/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2024-10-12",
    versionChannel: "nightly",
    buildDate: "2024-10-12T00:05:59.291Z",
    commitHash: "95177748ce9f6dafb880aea912842a4ff633eaac",
};
